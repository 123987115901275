"use client";

import React from "react";
import { AppPage } from "@/components/apppage";
import { SignedIn, SignedOut } from "@clerk/nextjs";
export default function LandingPage() {
  return <AppPage title="Memory Atlas" rightHeader={<>
          <SignedIn>
            <a href="/home" className="bg-blue-600 text-white py-1 sm:py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors mr-4 sm:px-8 text-sm sm:text-base cursor-default">
              Open App
            </a>
          </SignedIn>
        </>} data-sentry-element="AppPage" data-sentry-component="LandingPage" data-sentry-source-file="page.tsx">
      <div className="mb-8">
        <img src="/icons/icon.png" alt="Memory Atlas Icon" className="w-48 h-48 mx-auto" />
        <h2 className="text-4xl font-extrabold mt-4">
          An Assistant for your household
        </h2>
        <p className="text-lg mt-2 text-gray-700">
          ... that learns and remembers what makes your home uniquely yours.
        </p>
      </div>

      {/* Embedded YouTube Video */}
      <div className="w-full max-w-2xl aspect-video mb-8 relative">
        <iframe className="absolute top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/KvoOVulJBNU?si=qTBsSNLXvoI57-pn" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>

      {/* How it Works Section */}
      <div className="max-w-3xl text-left">
        <h3 className="text-2xl font-bold mb-4">What does it do?</h3>
        <ul className="list-disc pl-8 space-y-2 text-gray-700">
          <li>
            <strong>Catalog with ease:</strong> Record your belongings using
            your voice or camera—no need for complicated setups.
          </li>
          <li>
            <strong>Ask natural questions:</strong> Look up that vegetarian
            cookbook you got from your mom, or any item, with simple language.
          </li>
          <li>
            <strong>Stay organized:</strong> Keep your to-do lists and shopping
            lists in check, all in one place.
          </li>
          <li>
            <strong>Conversational interface:</strong> Chat naturally with your
            assistant to manage tasks and updates in real time.
          </li>
          <li>
            <strong>A general AI assistant:</strong> Built on the same
            technology as ChatGPT, but with a purpose-built context.
          </li>
        </ul>
      </div>
    </AppPage>;
}